import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Moment from "moment";

export default {
  name: "TInterbankAssetsDetailAccountStatement",
  data() {
    return {
      property: {
        modal: {
          showModalSearchInterbankAccount: false,
          showModalResultListInterbankAssets: false,
        },
        animation: {
          submitLoading: false,
          isDownloadingFile: false,
          checkingInterbankAssets: {
            isLoading: false,
          },
        },
        listElement: {
          interbankAssetsStatement: {
            downloading: false,
            message: "",
          },
          interbankAssets: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          accountNumber: "",
          accountName: "",
          dateFrom: "",
          dateTo: "",
          sorting: "",
        },
        accessories: {
          day: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Mei",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Okt",
            "Nov",
            "Des",
          ],
        },
      },
      form: {
        dateFrom: "",
        dateTo: "",
        interbankAssets: {
          header: {
            accountNumber: 0,
            accountName: "",
            biCode: "",
            bankName: "",
            accountBranch: "",
            officeCode: "",
            officeName: "",
            officeAddress: "",
            balance: 0,
            nominalBlock: 0,
            balanceUsable: 0,
            interbankAssetsTypeName: "",
            startDate: "",
            endDate: "",
          },
        },
        checkingInterbankAssets: {
          accountNumber: "",
          accountName: "",
        },
      },
      options: {
        urutkanBerdasarkan: [
          { value: "ASC", text: "Terlama - Terbaru" },
          { value: "DESC", text: "Terbaru - Terlama" },
        ],
      },
      table: {
        header: {
          transaction: [
            {
              key: "transactionDate",
              label: "Tanggal",
            },
            {
              key: "documentProofNumber",
              label: "No Bukti Dokumen",
            },
            {
              key: "transactionCode",
              label: "No Transaksi",
            },
            {
              key: "description",
              label: "Keterangan",
            },
            {
              key: "nominalDebit",
              label: "Mutasi Debet",
            },
            {
              key: "nominalCredit",
              label: "Mutasi Kredit",
            },
            {
              key: "balanceEnd",
              label: "Saldo Akhir",
            },
          ],
        },
        data: {
          transaction: [],
          interbankAssets: [],
        },
      },
      session: {
        checkingInterbankAssets: {
          accountNumber: "",
          accountName: "",
        },
      },
    };
  },
  methods: {
    Moment: Moment,
    async onDownloadFile(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        fileType: fileType,
        accountNumber: this.form.interbankAssets.header.accountNumber,
        dateTo: this.form.dateTo,
        dateFrom: this.form.dateFrom,
        sorting: this.property.filterDto.sorting,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "account-statement/aba/files",
          payload: payload,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Account_Statement_Interbank_Assets.${
          fileType === "xls" ? "xlsx" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      } catch (error) {
        console.log(error);
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    async searchInterbankAssetsDetailAccountStatement(props) {
      this.property.animation.checkingInterbankAssets.isLoading = true;
      this.property.filterDto.accountNumber = props.row.accountNumber;
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          this.property.animation.submitLoading = true;
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "transaction",
            reqUrl: "interbankAssetsDetail/account-statement",
            payload: {
              accountNumber: this.property.filterDto.accountNumber,
              dateFrom: this.property.filterDto.dateFrom,
              dateTo: this.property.filterDto.dateTo,
              sorting: this.property.filterDto.sorting,
            },
          });
          await this.simpleWait(2000);
          if (resp.data.code === "SUCCESS") {
            this.clearModalResultListInterbankAssets();
            this.table.data.transaction = [];
            this.property.modal.showModalResultListInterbankAssets = false;
            this.form.dateFrom = this.property.filterDto.dateFrom;
            this.form.dateTo = this.property.filterDto.dateTo;
            this.property.filterDto.accountNumber = "";
            this.property.filterDto.dateFrom = "";
            this.property.filterDto.dateTo = "";
            this.closeModalCheckingInterbankAssets();
            await this.simpleWait(500);
            this.form.interbankAssets.header.officeName =
              resp.data.data.header.officeName;
            this.form.interbankAssets.header.officeAddress =
              resp.data.data.header.officeAddress;
            this.form.interbankAssets.header.accountName =
              resp.data.data.header.accountName;
            this.form.interbankAssets.header.accountNumber =
              resp.data.data.header.accountNumber;
            this.form.interbankAssets.header.balance =
              resp.data.data.header.balance;
            this.form.interbankAssets.header.nominalBlock =
              resp.data.data.header.nominalBlock;
            this.form.interbankAssets.header.balanceUsable =
              resp.data.data.header.balanceUsable;
            this.form.interbankAssets.header.interbankAssetsTypeName = resp.data
              .data.header.rinterbankAssetsType
              ? resp.data.data.header.rinterbankAssetsType
                  .interbankAssetsTypeName
              : "";
            if (resp.data.data.detail) {
              this.table.data.transaction = resp.data.data.detail;
              this.preparePeriode();
            } else {
              this.table.data.transaction = [];
              this.property.listElement.interbankAssetsStatement.message =
                "Belum Ada Transaksi";
            }
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          await this.simpleWait(1000);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          await this.simpleWait(1000);
          this.property.animation.checkingInterbankAssets.isLoading = false;
        }
      }
    },
    closeModalCheckingInterbankAssets() {
      this.property.modal.showModalSearchInterbankAccount = false;
      this.closeModalSearchInterbankAssets();
    },
    closeModalSearchInterbankAssets() {
      this.property.filterDto.accountNumber = "";
      this.property.filterDto.accountName = "";
      this.property.filterDto.dateFrom = "";
      this.property.filterDto.dateTo = "";
    },
    clearModalResultListInterbankAssets() {
      this.table.data.interbankAssets = [];
      this.property.listElement.interbankAssets.rows = 0;
      this.property.listElement.interbankAssets.currentPage = 1;
      this.property.listElement.interbankAssets.perPage = 5;
    },
    async checkingAvailabilityInterbankAssets() {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.session.checkingInterbankAssets.accountNumber = this.form.checkingInterbankAssets.accountNumber;
        this.session.checkingInterbankAssets.bankName = this.form.checkingInterbankAssets.bankName;
        this.form.checkingInterbankAssets.accountNumber = "";
        this.form.checkingInterbankAssets.bankName = "";

        try {
          this.property.animation.checkingInterbankAssets.isLoading = true;
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "transaction",
            reqUrl: "interbankAssets",
            payload: {
              accountNumber: this.session.checkingInterbankAssets.accountNumber,
              bankName: this.session.checkingInterbankAssets.bankName,
              interbankAssetsTypeId: "",
              page: 0,
              size: this.property.listElement.interbankAssets.perPage,
            },
          });
          await this.simpleWait(500);
          if (resp.data.code === "SUCCESS") {
            this.form.interbankAssets.header.startDate = "";
            this.form.interbankAssets.header.endDate = "";
            this.table.data.interbankAssets = resp.data.data.content;
            this.property.modal.showModalSearchInterbankAccount = false;
            this.property.modal.showModalResultListInterbankAssets = true;
            this.property.listElement.interbankAssets.rows =
              resp.data.data.totalElements;
          } else if (resp.data.code === "DATA NOT FOUND") {
            this.session.checkingInterbankAssets.accountNumber = this.form.checkingInterbankAssets.accountNumber;
            this.form.checkingInterbankAssets.accountNumber = "";
            this.session.checkingInterbankAssets.bankName = this.form.checkingInterbankAssets.bankName;
            this.form.checkingInterbankAssets.bankName = "";
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `Nomor Rekening Tidak Tersedia!`,
              confirmText: "Ok",
              type: "is-danger",
            });
          } else {
            this.session.checkingInterbankAssets.accountNumber = this.form.checkingInterbankAssets.accountNumber;
            this.form.checkingInterbankAssets.accountNumber = "";
            this.session.checkingInterbankAssets.bankName = this.form.checkingInterbankAssets.bankName;
            this.form.checkingInterbankAssets.bankName = "";
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          await this.simpleWait(500);
          this.session.checkingInterbankAssets.accountNumber = this.form.checkingInterbankAssets.accountNumber;
          this.form.checkingInterbankAssets.accountNumber = "";
          this.session.checkingInterbankAssets.bankName = this.form.checkingInterbankAssets.bankName;
          this.form.checkingInterbankAssets.bankName = "";
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          await this.simpleWait(500);
          this.property.animation.checkingInterbankAssets.isLoading = false;
        }
      }
    },
    async changePaginationInterbankAssets(event) {
      this.table.data.interbankAssets = [];
      this.property.listElement.interbankAssets.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "interbankAssets",
          payload: {
            accountNumber: this.session.checkingInterbankAssets.accountNumber,
            bankName: this.session.checkingInterbankAssets.bankName,
            interbankAssetsTypeId: this.property.filterDto.interbankAssetsId,
            page: event - 1,
            size: this.property.listElement.interbankAssets.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.interbankAssets = resp.data.data.content;
            this.property.listElement.interbankAssets.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetInterbankAssets(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorInterbankAssets(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.interbankAssets.downloading = false;
        }, timeout);
      }
    },
    handleErrorInterbankAssets(error) {
      console.log(error.response);
      this.table.data.interbankAssets = [];
      this.property.listElement.interbankAssets.rows = 0;
      this.property.listElement.interbankAssets.currentPage = 1;
      this.property.listElement.interbankAssets.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    failedGetInterbankAssets(resp) {
      this.table.data.interbankAssets = [];
      this.property.listElement.interbankAssets.rows = 0;
      this.property.listElement.interbankAssets.message = resp.data.message;
    },
    // printInterbankAssetsAccountStatement() {
    //   const doc = new jsPDF({
    //     orientation: "portrait",
    //     unit: "in",
    //     format: "letter",
    //   });
    //   const headerFinal = [];
    //   const header = this.table.header.transaction.map((index) => {
    //     headerFinal.push(index.label);
    //   });
    //   const finalDataTable = [];
    //   var numbering = 1;
    //   const loopDataTable = this.table.data.transaction.map((index) => {
    //     finalDataTable.push([
    //       `${Moment(index.transactionDate).format("LL")}`,
    //       index.documentProofNumber,
    //       index.transactionCode,
    //       index.description,
    //       `${
    //         index.nominalDebit
    //           ? index.nominalDebit
    //               .toFixed(2)
    //               .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    //           : 0
    //       }`,
    //       `${
    //         index.nominalCredit
    //           ? index.nominalCredit
    //               .toFixed(2)
    //               .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    //           : 0
    //       }`,
    //       index.balaneEnd,
    //     ]);
    //   });
    //   var pageWidth =
    //     doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    //   doc.setFontSize(10).text(`Rekening Koran ABA`, pageWidth / 2 - 1, 0.8);
    //   doc.setFontSize(8).text(`Alamat Kantor Cabang`, 0.55, 1.3);
    //   doc
    //     .setFontSize(8)
    //     .text(` ${this.form.interbankAssets.header.officeName}`, 0.55, 1.5);
    //   doc.setFontSize(8).text(`Kepada Yth`, 0.55, 1.8);
    //   doc
    //     .setFontSize(8)
    //     .text(`${this.form.interbankAssets.header.accountName}`, 0.55, 2.0);
    //   doc
    //     .setFontSize(8)
    //     .text(`${this.form.interbankAssets.header.officeAddress}`, 0.55, 2.2);
    //   doc
    //     .setFontSize(8)
    //     .text(
    //       `Tipe Rekening                       :  ${this.form.interbankAssets.header.interbankAssetsTypeName}`,
    //       pageWidth / 2 + 1,
    //       1.3
    //     );
    //   doc
    //     .setFontSize(8)
    //     .text(
    //       `Nomor Rekening                   :   ${this.form.interbankAssets.header.accountNumber}`,
    //       pageWidth / 2 + 1,
    //       1.5
    //     );
    //   doc
    //     .setFontSize(8)
    //     .text(
    //       `Saldo Akhir                            :  ${
    //         this.form.interbankAssets.header.balance
    //           ? this.form.interbankAssets.header.balance
    //               .toFixed(2)
    //               .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    //           : 0
    //       } `,
    //       pageWidth / 2 + 1,
    //       1.7
    //     );
    //   doc
    //     .setFontSize(8)
    //     .text(
    //       `Jumlah Blokir                         :  ${
    //         this.form.interbankAssets.header.nominalBlock
    //           ? this.form.interbankAssets.header.nominalBlock
    //               .toFixed(2)
    //               .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    //           : 0
    //       } `,
    //       pageWidth / 2 + 1,
    //       1.9
    //     );
    //   doc
    //     .setFontSize(8)
    //     .text(
    //       `Saldo Yang Bisa Digunakan  :  ${
    //         this.form.interbankAssets.header.balanceUsable
    //           ? this.form.interbankAssets.header.balanceUsable
    //               .toFixed(2)
    //               .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    //           : 0
    //       } `,
    //       pageWidth / 2 + 1,
    //       2.1
    //     );
    //   doc
    //     .setFontSize(8)
    //     .text(
    //       `Periode  ${this.form.interbankAssets.header.startDate} S/D ${this.form.interbankAssets.header.endDate}`,
    //       pageWidth / 2 + 1,
    //       2.3
    //     );

    //   doc.autoTable({
    //     columnStyles: {
    //       0: {
    //         halign: "center",
    //         font: "courier",
    //         fontSize: 6,
    //         fontStyle: "normal",
    //       },
    //       1: {
    //         halign: "center",
    //         font: "courier",
    //         fontSize: 6,
    //         fontStyle: "normal",
    //       },
    //       2: {
    //         halign: "center",
    //         font: "courier",
    //         fontSize: 6,
    //         fontStyle: "normal",
    //       },
    //       3: {
    //         halign: "center",
    //         font: "courier",
    //         fontSize: 6,
    //         fontStyle: "normal",
    //       },
    //       4: {
    //         halign: "center",
    //         font: "courier",
    //         fontSize: 6,
    //         fontStyle: "normal",
    //       },
    //       5: {
    //         halign: "center",
    //         font: "courier",
    //         fontSize: 6,
    //         fontStyle: "normal",
    //       },
    //       6: {
    //         halign: "center",
    //         font: "courier",
    //         fontSize: 6,
    //         fontStyle: "normal",
    //       },
    //     },
    //     headStyles: {
    //       halign: "center",
    //       fontSize: 7,
    //       fontStyle: "bold",
    //       font: "normal",
    //     },
    //     head: [headerFinal],
    //     theme: "striped",
    //     body: finalDataTable,
    //     margin: { top: 2.8 },
    //     halign: "center",
    //     didDrawPage: function(data) {
    //       data.settings.margin.top = 0.9;
    //     },
    //   });
    //   doc.autoPrint();

    //   const hiddFrame = document.createElement("iframe");
    //   hiddFrame.style.position = "fixed";
    //   // "visibility: hidden" would trigger safety rules in some browsers like safari，
    //   // in which the iframe display in a pretty small size instead of hidden.
    //   // here is some little hack ~
    //   hiddFrame.style.width = "1px";
    //   hiddFrame.style.height = "1px";
    //   hiddFrame.style.opacity = "0.01";
    //   const isSafari = /^((?!chrome|android).)*safari/i.test(
    //     window.navigator.userAgent
    //   );
    //   if (isSafari) {
    //     // fallback in safari
    //     hiddFrame.onload = () => {
    //       try {
    //         hiddFrame.contentWindow.document.execCommand("print", false, null);
    //       } catch (e) {
    //         hiddFrame.contentWindow.print();
    //       }
    //     };
    //   }
    //   hiddFrame.src = doc.output("bloburl");
    //   document.body.appendChild(hiddFrame);
    // },
    preparePeriode() {
      if (this.table.data.transaction !== []) {
        // START DATE
        this.form.interbankAssets.header.startDate = `
        ${Moment(this.table.data.transaction[0].transactionDate).format("LL")}
        `;

        // END DATE
        this.form.interbankAssets.header.endDate = `
        ${Moment(
          this.table.data.transaction[this.table.data.transaction.length - 1]
            .transactionDate
        ).format("LL")}
        `;
      }
    },
    handleErrorInterbankAssetAccountStatement(error) {
      console.log(error.response);
      this.table.data.transaction = [];
      this.property.listElement.interbankAssetsStatement.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async sortingInterbankAssetAccountStatement(event) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.table.data.transaction = [];
        this.property.listElement.interbankAssetsStatement.isLoading = true;

        try {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "transaction",
            reqUrl: "interbankAssetsDetail/account-statement",
            payload: {
              accountNumber: this.form.interbankAssets.header.accountNumber,
              dateFrom: this.form.dateFrom,
              dateTo: this.form.dateTo,
              sorting: event,
            },
          });
          if (resp.data.code === "SUCCESS") {
            if (resp.data.data.detail) {
              this.table.data.transaction = resp.data.data.detail;
              this.preparePeriode();
            } else {
              this.table.data.transaction = [];
              this.property.listElement.interbankAssetsStatement.message =
                "Belum Ada Transaksi";
            }
          } else {
            this.table.data.transaction = [];
            this.property.listElement.interbankAssetsStatement.message =
              resp.data.message;
          }
        } catch (error) {
          this.handleErrorInterbankAssetAccountStatement(error);
        } finally {
          await this.simpleWait(1000);
          this.property.listElement.interbankAssetsStatement.isLoading = false;
        }
      }
    },
    prepareInterbankAssetAccountStatement() {
      this.property.filterDto.sorting = "ASC";
      this.property.listElement.interbankAssetsStatement.message =
        "Pilih Nomor Rekening Terlebih Dahulu";
    },
  },
  mounted() {
    this.prepareInterbankAssetAccountStatement();
    Moment.locale("id");
  },
};
